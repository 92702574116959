import type { SearchOptions } from '@algolia/client-search'
import { useZenniAlgoliaSearch } from '#imports'
import { useGuestStore } from '~/store/guest'
export const useEmployeeAlgolia = <T>(indexName?: string) => {
  const { gift } = useGuestStore()
  const {
    search: baseSearch,
    result,
    loading,
    searchGetters,
  } = useZenniAlgoliaSearch<T>(indexName)
  const search = async (
    queryParams: {
      query: string
      requestOptions: SearchOptions
    },
    includeFrameFilters: boolean = true,
  ) => {
    // we will asume that if no objectType is passed, then we are searching for products
    const filterHasObjectType =
      queryParams.requestOptions.filters?.includes('objectType')
    return await baseSearch(
      {
        ...queryParams,
        requestOptions: {
          ...queryParams.requestOptions,
          filters: `${queryParams.requestOptions.filters || ''}${
            !filterHasObjectType ? ' AND objectType:"product"' : ''
          }`,
        },
      },
      gift?.catalogFilter,
      includeFrameFilters ? gift?.frameFilter : undefined,
    )
  }
  return {
    search,
    result,
    loading,
    searchGetters,
  }
}
